export interface PhysicalAddress {
    firstName?: string | null;
    lastName?: string | null;
    name: string | null;
    address: string;
    addressMore: string | null;
    postcode: string;
    city: string;
    state: string | null;
    country: string | null;
    countryCode: string | null;
    isAutocomplete: boolean | null;
    latitude: number | null;
    longitude: number | null;
}

export type OptionalPhysicalAddress = {
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    address?: string;
    addressMore?: string | null;
    postcode?: string;
    city?: string;
    state?: string | null;
    country?: string | null;
    countryCode?: string | null;
    isAutocomplete?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    timezone?: string | null;
    phoneNumber?: string | null;
};

export type Address = {
    uid?: string | null;
    gender: 1 | 2 | 3 | null;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    company: string | null;
    phone: string | null;
    inputType?: 'Capadresse' | 'Manual' | 'GoogleMap' | null;
} & PhysicalAddress;

/** Representing Problem Details in HTTP APIs */
export interface Rfc7807 {
    type: string;
    title: string;
    status: number;
    detail: string;
    'invalid-params': InvalidParams;
}

/**
 * @minItems 1
 * @uniqueItems true
 */
export type InvalidParams =
    | {
          name: string;
          reason: string;
          reasonKey?: string;
      }[]
    | null;

export interface NewsletterSubscriber {
    id?: number | null;
    email: string;
    salesforceId?: string | null;
    keycloakId?: string | null;
    /** @deprecated */
    sezaneLegacyId?: string | null;
    /** @deprecated */
    octobreLegacyId?: string | null;
    sezaneSubscriptionStatus: boolean | null;
    octobreSubscriptionStatus: boolean | null;
    /** @format date-time */
    sezaneSubscriptionDate?: string | null;
    /** @format date-time */
    octobreSubscriptionDate?: string | null;
    subscriptionLocale: string;
}

export type UserAddress = {
    /** @format uuid */
    uid?: string | null;
    /** For the moment, key used by the Checkout during the render of the addresses listing */
    id?: number | null;
    /**
     * A flag to know if the address is the default one for the user
     * @default false
     */
    isDefault?: boolean;
    address?: Address;
};

export type ShippingShop = {
    uid: string;
    name: string;
    description: string;
    address: PhysicalAddress;
} | null;

export type PickUpAddress = {
    pickUpId: string;
    address: PhysicalAddress;
} | null;

export type CartAddresses = {
    shipping: UserAddress;
    lastMinuteShopping: UserAddress;
    billing: UserAddress;
    shop: ShippingShop;
    pickup: PickUpAddress;
    /** @default false */
    billingAddressSameAsShipping: boolean;
} | null;

export interface FtpFactoryInput {
    /** the aws s3 bucket name in which the file is stored */
    bucket: string | null;
    /** path + file name */
    key: string | null;
}

export type SmsSubscription = {
    brandCode?: string;
    status?: boolean | null;
};

export type PostalMailSubscription = {
    brandCode?: string;
    status?: boolean | null;
};

export interface ProductStatus {
    label: 'in_other_basket' | 'available_soon' | 'available' | 'unavailable' | 'preorderable';
    /** @format date-time */
    preorderDate?: string | null;
}

export type ProductPrice = {
    amount: number;
    currency: string;
} | null;

export interface TranslatableObject {
    translationKey: string;
    translationValue: string;
}

export interface OrderMultiProduct {
    ean?: string;
    orderItemProductId?: string;
    /** Name of the model (localized) */
    label?: string;
    /** Size value (localized) */
    sizeLabel?: string;
    /** Color of the variant (localized) */
    colorLabel?: string;
    /** Cloudinary url for the main media */
    imageUrl?: string;
}

export interface CorporateSocialResponsibility {
    key?: string;
    label?: string;
    description?: string | null;
    link?: string | null;
}

export type ProductProperty = {
    /**
     * Equals to legacyId or OctobreLegacyId. Do not use anymore
     * @deprecated
     */
    productId?: number;
    hash?: string | null;
    name?: string | null;
    price?: number;
    crossedOutPrice?: number | null;
    displayPrice?: string;
    displayCrossedOutPrice?: string | null;
    /** @example "standard" */
    vatType?: string | null;
    link?: string | null;
    selectedVariant?: VariantProperty;
    variants?: VariantProperty[];
    /** A customisation option defined in the BO for a product */
    customisation?: Customisation;
    /** product subcategory */
    subcategory?: string | null;
    customisationMediaUrl?: string | null;
    hsCode?: Record<string, any>;
    taxCode?: string | null;
    madeInCode?: string | null;
    midCode?: string | null;
    weaving?: string | null;
    composition?: string | null;
    gender?: string | null;
    csr?: string | null;
    csrExtended?: CorporateSocialResponsibility[] | null;
    dangerous?: boolean | null;
    dangerousClassification?: number | null;
    dangerousOnu?: string | null;
    dangerousId8000?: boolean | null;
    weight?: number | null;
    description?: string | null;
    /**
     * product category uuid
     * @default ""
     */
    categoryUuid?: string | null;
    /**
     * product category label
     * @default ""
     */
    categoryLabel?: string | null;
};

export type EcardProperty = {
    /**
     * empty string
     * @default ""
     */
    ean?: string;
    category?: string | null;
    productType?: string | null;
    name?: string | null;
    slug?: string | null;
    color?: string | null;
    image?: string | null;
    priceTaxIncluded?: number;
    priceTaxExcluded?: number | null;
    message?: string | null;
    senderLastname?: string;
    senderFirstname?: string;
    receiverFirstname?: string;
    receiverLastname?: string;
    receiverEmail?: string;
    /** @format date-time */
    sendAt?: string;
    remainingAmount?: string | null;
};

export type VariantProperty = {
    variantId?: number;
    storeVariantId?: number | null;
    hash?: string | null;
    selected?: boolean;
    /** @default true */
    available?: boolean;
    /** @default false */
    availableSoon?: boolean;
    name?: string | null;
    code?: string;
    link?: string | null;
    imageUrl?: string;
    photos?: ProductVariantPhoto[] | null;
    price?: number;
    crossedOutPrice?: number | null;
    displayPrice?: string;
    displayCrossedOutPrice?: string | null;
    selectedItem?: ItemProperty;
    items?: ItemProperty[];
    tracking?: VariantPropertyTracking;
    preorder?: boolean;
    preorderDate?: string | null;
    preorderText?: string | null;
    /** A customisation option defined in the BO for a product */
    customisation?: Customisation | null;
    /** @default false */
    isCrossSellPopin?: boolean;
    /** UUID de la variant */
    uuid?: string | null;
    /** legacy model ID */
    legacyModelCode?: number | null;
    stock?: number | null;
};

export type ItemProperty = {
    uuid?: string;
    itemId?: number;
    hash?: string | null;
    /** @default true */
    available?: boolean;
    size?: string;
    link?: string;
    ean?: string;
    /** @default false */
    inOtherBasket?: boolean;
    /** @default false */
    lastMinuteShoppingAvailable?: boolean;
    /** @default null */
    lastCallThreshold?: number | null;
    /** @default false */
    isLastCall?: boolean;
    stock?: number | null;
};

/** A customisation applied on an item */
export type OrderItemCustomisation = {
    rows?: string[] | null;
    color?: string | null;
    position?: string | null;
    customisationMediaUrl?: string | null;
    /** A customisation option defined in the BO for a product */
    service?: Customisation | null;
    type?: string | null;
    format?: string | null;
};

export interface VariantPropertyTracking {
    /** @deprecated */
    legacyId?: string;
    ean?: string;
    name?: string;
    price?: number;
    /** @default "Sezane" */
    brand?: string;
    category?: string;
    categoryReference?: string;
    variantColor?: string;
    quantity?: number;
    productUuid?: string;
    sizeUuid?: string;
}

export interface OrderVoucher {
    id?: number;
    uid?: string | null;
    code?: string;
    /** Price in cents */
    price?: number;
    /** Price to display, with currency */
    displayPrice?: string;
}

/** Light OrderProduct model specific for Packaging */
export interface PackagingProduct {
    /** ean of the product with packaging */
    ean?: string;
    /** Name of the model */
    label?: string;
    /** Size value */
    sizeLabel?: string;
    /** Color of the variant */
    colorLabel?: string;
    /** Cloudinary url for the main media */
    imageUrl?: string;
    /**
     * Property to indicate it's a product with packaging
     * @default true
     */
    packaging?: boolean;
    /**
     * Packaging options available for this product
     * @default []
     */
    packagingOptions?: PackagingOption[];
}

export interface PackagingOption {
    /** Label for the packaging option */
    title: string;
    /** Corresponding reference for this option in the legacy */
    packagingReference?: string;
    ean: string;
    /** Is this option selected or not */
    selected: boolean;
}

/** A customisation option defined in the BO for a product */
export type Customisation = {
    name?: string | null;
    /** @default true */
    optional?: boolean | null;
    description?: string | null;
    price?: string | null;
    totalPrice?: string | null;
    authorizedCharacters?: string | null;
    authorizedCharactersPattern?: string | null;
    whitelistedCharacters?: string | null;
    fullValue?: string | null;
    /** Lines of text for this customisation */
    rows?: {
        /** Maximum number of characters allowed for this line */
        limit?: number | null;
        /** Value provided for this line by the user */
        value?: string | null;
    }[];
};

export interface ProductVariantPhoto {
    mainUrl?: string;
    zoomUrl?: string;
    swiperUrl?: string;
    variantUrl?: string;
    crossSellUrl?: string;
    /** @default "image" */
    mediaType?: string;
    models?: ProductVariantPhotoModel[];
    originalPhotoId?: number;
    canRequireEdition?: boolean;
}

export interface ProductVariantPhotoModel {
    name?: string;
    height?: number;
    size?: string;
}

export interface OrderProduct {
    ean?: string;
    orderItemProductId?: string;
    /** Name of the model (localized) */
    label?: string;
    /** Size value (localized) */
    sizeLabel?: string;
    /** Color of the variant (localized) */
    colorLabel?: string;
    /** Cloudinary url for the main media */
    imageUrl?: string;
    /** Url for the product */
    productUrl?: string;
    productProperty?: ProductProperty;
    ecardProperty?: EcardProperty;
    /**
     * Is this article of packaging type
     * @default false
     */
    packaging?: boolean;
    /** Number of items (simple delivery + last minute shopping) */
    quantity?: number;
    /** Number of items for last minute shopping */
    lastMinuteQuantity?: number;
    /** Number of items for simple delivery */
    simpleDeliveryQuantity?: number;
    /**
     * Number of items from retail
     * @default 0
     */
    retailQuantity?: number;
    /** Unit price of the article, ready to be displayed, with currency */
    unitDisplayPrice?: string;
    localeUnitDisplayPrice?: string | null;
    /** Unit price of the article, in cents */
    unitPrice?: number;
    localeUnitPrice?: number | null;
    /** Unit catalog price of the article, ready to be displayed, with currency */
    catalogDisplayPrice?: string;
    /** Is the product in an archive sale ? */
    archives?: boolean;
    /** Crossed out price of the article, ready to be displayed, with currency */
    crossedOutDisplayPrice?: string | null;
    localeCrossedOutDisplayPrice?: string | null;
    /** Crossed out price of the article, in cents */
    crossedOutPrice?: number | null;
    localeCrossedOutPrice?: number | null;
    /** Total price of the articles, ready to be displayed, with currency */
    totalDisplayPrice?: string;
    localeTotalDisplayPrice?: string | null;
    /** Total price of the articles, in cents */
    totalPrice?: number;
    localeTotalPrice?: number | null;
    /** Total crossed out price of the articles, ready to be displayed, with currency */
    totalCrossedOutDisplayPrice?: string | null;
    localeTotalCrossedOutDisplayPrice?: string | null;
    /** Total Crossed out price of the articles, in cents */
    totalCrossedOutPrice?: number | null;
    localeTotalCrossedOutPrice?: number | null;
    /**
     * Indicates if customization is possible on this item
     * @default false
     */
    isCustomizable?: boolean;
    /** A customisation applied on an item */
    customisation?: OrderItemCustomisation | null;
    /**
     * Packaging options available for this product
     * @default []
     */
    packagingOptions?: PackagingOption[];
    /** @default false */
    gift?: boolean;
    returnableQuantity?: number | null;
    /** @default [] */
    multiProducts?: OrderMultiProduct[];
    /** @format date-time */
    createdAt?: string | null;
}

export interface Size {
    selected?: boolean;
    itemId?: number | null;
    ean?: string | null;
    link?: string | null;
    size?: string | null;
    available?: boolean | null;
    /** @default false */
    webStockAvailable?: boolean;
}

export type ShopList = Shop[];

export type Shop = {
    uid?: string | null;
    /** @minLength 1 */
    brandCode: string;
    /** @minLength 1 */
    description: string;
    /** @default false */
    enabled?: boolean;
    address: PhysicalAddress;
    openHours: OpenHours | null;
    [key: string]: any;
};

export type OpenHours = {
    day1?: ShopOpenHour[] | null;
    day2?: ShopOpenHour[] | null;
    day3?: ShopOpenHour[] | null;
    day4?: ShopOpenHour[] | null;
    day5?: ShopOpenHour[] | null;
    day6?: ShopOpenHour[] | null;
    day7?: ShopOpenHour[] | null;
};

export interface ShopOpenHour {
    id?: number;
    day: number;
    startTime: string;
    endTime: string;
}

export type ShippingMode = {
    uid?: string;
    name?: string;
    type?: string;
    description?: string | null;
    deliveryDelay?: string | null;
    shippingPrice?: number;
    shippingDisplayPrice?: string;
    /** @deprecated */
    legacyId?: number | null;
    maxDeliveryQuantityReached?: boolean | null;
    maxPouchFluxQuantityReached?: boolean | null;
    isAvailable?: boolean | null;
    unavailabilityReason?: string | null;
    shippingModeUid?: string;
};

export interface PickUp {
    id?: string;
    openingHours?: {
        monday?: PickUpOpeningPeriod[];
        tuesday?: PickUpOpeningPeriod[];
        wednesday?: PickUpOpeningPeriod[];
        thursday?: PickUpOpeningPeriod[];
        friday?: PickUpOpeningPeriod[];
        saturday?: PickUpOpeningPeriod[];
        sunday?: PickUpOpeningPeriod[];
    };
    pickUpAddress?: PhysicalAddress;
}

export interface PickUpOpeningPeriod {
    start?: string;
    end: string;
}

export interface ShopLmsAvailable {
    available?: boolean;
}

export interface FreeShippingThreshold {
    freeShippingThresholds?: FreeShippingThresholds;
}

export type FreeShippingThresholds = Record<
    string,
    {
        currencies?: {
            currencyCode: string;
            amount: number;
        }[];
    }
>;

export type OrdersStatuses = {
    reference?: number;
    status?: string;
}[];

export type Cart = {
    /**
     * id encrypted
     * @format uuid
     */
    number?: string;
    userId?: string | null;
    userFullName?: string | null;
    userEmail?: string | null;
    /**
     * Creation date
     * @format date-time
     */
    createdAt?: string;
    /**
     * Last update date
     * @format date-time
     */
    updatedAt?: string;
    /**
     * Expiration date for a cart
     * @format date-time
     */
    expireAt?: string;
    /** Total number of products in the order */
    totalProductQuantity?: number;
    /** Number of products for last minute shopping */
    lastMinuteQuantity?: number;
    /** Number of products for simple delivery */
    simpleDeliveryQuantity?: number;
    /** Number of products from retail */
    retailQuantity?: number;
    /** Amount tax included for all the articles, in cents */
    subTotalPrice?: number;
    /** Total VAT for all the articles, in cents */
    subTotalPriceVat?: number;
    /** Amount tax included for all the articles, with currency */
    subTotalDisplayPrice?: string;
    /** Amount tax included for the shipping, in cents */
    shippingPrice?: number;
    /** Total VAT for the shipping, in cents */
    shippingPriceVat?: number;
    /** Amount tax included for the shipping, with currency */
    shippingDisplayPrice?: string;
    /** Amount tax included of the discount, in cents */
    discountPrice?: number;
    /** Amount tax included of the discount, with currency */
    discountDisplayPrice?: string;
    /** Total vat, in cents */
    totalVat?: number;
    /** Total vat, with currency */
    totalVatDisplayed?: string;
    /** Total price tax included without shipping, in cents */
    totalPriceWithoutShipping?: number;
    /** Total price tax included without shipping, with currency */
    totalDisplayPriceWithoutShipping?: string;
    /** Total price tax included, in cents */
    totalPrice?: number;
    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
    minorUnitsTotalPrice?: number;
    /** Total price tax included, with currency */
    totalDisplayPrice?: string;
    /** Amount sales tax, in cents */
    amountSalesTax?: number;
    /** Amount sales tax, with currency */
    amountSalesTaxDisplay?: string;
    /** Amount duties and other fees, in cents */
    amountDutiesAndOtherFees?: number;
    /** Amount duties and other fees, with currency */
    amountDutiesAndOtherFeesDisplay?: string;
    /** Amount duties and other fees threshold, in cents */
    amountDutiesAndOtherFeesThreshold?: number | null;
    /** Amount duties and other fees threshold, with currency */
    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
    currencyCode?: string | null;
    products?: OrderProduct[];
    vouchers?: OrderVoucher[];
    userCredits?: OrderVoucher[];
    ecards?: OrderVoucher[];
    packaging?: PackagingProduct[];
    addresses?: CartAddresses;
    giftMessage?: any;
    lastMinuteShoppingMode?: ShippingMode;
    shippingMode?: ShippingMode;
    shippingCountryCode?: string | null;
    shippingStateCode?: string | null;
    /** @default true */
    needAddress?: boolean;
    /** @default true */
    enableGiftMessage?: boolean;
    /** Commercial operation with code applied to the cart. (Had kept the old name) */
    promotionalCampaigns?: {
        id?: number;
        uid?: string;
        /** Code of the commercial operation */
        code?: string;
    }[];
    localeCurrencyCode?: string | null;
    localeSubTotalPrice?: number | null;
    localeSubTotalDisplayPrice?: string | null;
    localeTotalPriceWithoutShipping?: number | null;
    localeTotalDisplayPriceWithoutShipping?: string | null;
    localeTotalPrice?: number | null;
    localeTotalDisplayPrice?: string | null;
    localeShippingPrice?: number | null;
    localeShippingDisplayPrice?: string | null;
    localeTotalVat?: number | null;
    localeTotalVatDisplayed?: string | null;
    localeAmountSalesTax?: number | null;
    localeAmountSalesTaxDisplay?: string | null;
    localeAmountDutiesAndOtherFees?: number | null;
    localeAmountDutiesAndOtherFeesDisplay?: string | null;
    localeDiscountPrice?: number | null;
    localeDiscountDisplayPrice?: string | null;
    refund_hold_amount?: number | null;
};

export type AddProductRequestBody = {
    /** List of ean to add to the cart */
    eans: string[];
    /**
     * Is the product added as a last minute shopping product
     * @default false
     */
    lms: boolean;
    /** Multi product uid */
    multiProductUid?: string | null;
    /** A customisation applied on an item */
    customisation: ItemCustomisation;
};

/** A customisation applied on an item */
export type ItemCustomisation = {
    rows?: string[] | null;
    color?: string | null;
    position?: string | null;
    type?: string | null;
    format?: string | null;
};

export interface SelectPackagingRequestBody {
    uuids?: string[];
}

export interface EcardCode {
    /** Ecard Code */
    code?: string;
}

export interface CreditCode {
    /** Credit Code */
    code?: string;
}

/** commercial operation */
export interface CommercialOperation {
    id?: number;
    /**
     * public id
     * @format uuid
     */
    uid?: string;
    /** Translate title of the commercial operation */
    title?: string;
    /** Translate description of the commercial operation */
    description?: string;
    /**
     * Option de distribution pour l'opération si elle offre des produits
     * @default null
     */
    distributionMode?: 'prioritized_sequential' | 'random_sequential' | 'simultaneous' | 'customer_choice' | null;
    /**
     * Offered products if the commercial operation is this kind of operation
     * @default []
     */
    products?: ProductProperty[];
    /**
     * True if only for display. False if an action to add is expected
     * @default false
     */
    displayOnly?: boolean;
}

export interface User {
    id?: number | null;
    uuid?: string;
    salesforceId?: string | null;
    email?: string;
    /** @min 0 */
    nbOrders?: number | null;
    /** @min 0 */
    nbWebOrders?: number | null;
    /** @min 0 */
    nbRetailOrders?: number | null;
    /** @format date-time */
    lastOrderDate?: string | null;
    gender?: number | null;
    firstName?: string | null;
    lastName?: string | null;
    /** @format date-time */
    birthDate?: string | null;
    phone?: string | null;
    sezaneLegacyId?: number | null;
    octobreLegacyId?: number | null;
    /** @default null */
    subscribedToInvitationsEmails?: boolean | null;
    attributsBatch?: string[] | null;
    locale?: string | null;
    /** @default false */
    useKeycloakIdForAdyen?: boolean;
    smsSubscriptions?: SmsSubscription[] | null;
    idClientBarcodePath?: string | null;
    keycloakUuidQRCodePath?: string | null;
    postalMailSubscriptions?: PostalMailSubscription[] | null;
}

export interface UserState {
    eans?: string[];
    cart?: Cart;
}

export interface UserStateAuthenticated {
    wishlist?: {
        eans?: string[];
    };
    cart?: Cart;
}

export interface Alert {
    id?: number;
    uid?: string;
    userUuid?: string;
    ean?: string;
    /** @format date-time */
    createdAt?: string | null;
    product?: AlertProduct;
}

export type AlertProduct = {
    productId?: number | null;
    variantId?: number | null;
    imageURL?: string | null;
    name?: string | null;
    color?: string | null;
    code?: string | null;
    legacyCode?: string | null;
    ean?: string;
    sizeLabel?: string | null;
    stockWeb?: number | null;
    stockLogistic?: number | null;
    price?: number | null;
    displayPrice?: string | null;
    available?: boolean;
    lmsAvailable?: boolean | null;
    webStockAvailable?: boolean | null;
    sizes?: Size[] | null;
};

export interface RetrieveUserEcardBodySchema {
    email: string;
    code: string;
}

export interface RetrieveUserEcardResponseOkSchema {
    data?: {
        /** Ecard balance in cents */
        balance?: number;
        displayBalance?: string;
        currency?: string;
    };
}

export interface UserCredit {
    id?: number;
    uid?: string;
    code?: string;
    /** @deprecated */
    amount?: number;
    originalAmount?: number;
    currencyCode?: string;
    isAmountExhausted?: boolean;
    remainingAmount?: number | null;
    isExpired?: boolean;
    /** @format date-time */
    expiredAt?: string;
    /** @format date-time */
    usedAt?: string | null;
    orderId?: number | null;
    externalId?: string | null;
    /** Price to display, with currency */
    displayPrice?: string;
    /** Initial Amount to display, with currency */
    originalAmountDisplayPrice?: string;
}

export interface NewEanObject {
    /**
     * New ean to replace existing one
     * @example "1234567890123"
     */
    newEan: string;
}

export interface WishlistAndAlertItem {
    id?: number;
    uid?: string;
    imageUrl?: string | null;
    link?: string | null;
    ean?: string;
    /** @format date-time */
    createdAt?: string | null;
    name?: string | null;
    displayPrice?: string | null;
    price?: ProductPrice;
    displayCrossedOutPrice?: string | null;
    crossedOutPrice?: ProductPrice;
    status?: ProductStatus;
    color?: string | null;
    lmsAvailable?: boolean | null;
    available?: boolean | null;
    /** @default false */
    webStockAvailable?: boolean;
    size?: string | null;
    sizes?: (Size & {
        price?: ProductPrice;
        status?: ProductStatus;
        uuid?: string | null;
    })[];
    tracking?: {
        brand?: string | null;
        category?: {
            label?: string | null;
        };
        product?: {
            code?: string | null;
            uuid?: string | null;
        };
    };
}

export interface StuartPackageType {
    code?: string;
}

export interface StuartJobTransportType {
    code?: string;
}

export interface OrderRead {
    /** reference */
    number?: string;
    userId?: string;
    /**
     * Creation date
     * @format date-time
     */
    createdAt?: string;
    /**
     * Last update date
     * @format date-time
     */
    updatedAt?: string;
    invoiceLink?: string | null;
    /** Total number of products in the order (include LMS) */
    totalProductQuantity?: number;
    /**
     * Number of products for last minute shopping
     * @default 0
     */
    lastMinuteQuantity?: number;
    /**
     * Number of products for simple delivery
     * @default 0
     */
    simpleDeliveryQuantity?: number;
    /** Number of products from retail */
    retailQuantity?: number;
    /** Amount tax included for all the articles, in cents, without order discount */
    subTotalPrice?: number;
    /** Total VAT for all the articles, in cents */
    subTotalPriceVat?: number;
    /** Amount tax included for all the articles, with currency, without order discount */
    subTotalDisplayPrice?: string;
    /** display or hide prices on order */
    displayPrices?: boolean;
    /** order returnable? */
    isReturnable?: boolean;
    /** order cancelable? */
    isCancelable?: boolean;
    /** Amount tax included for the shipping, in cents */
    shippingPrice?: number;
    /** Total VAT for the shipping, in cents */
    shippingPriceVat?: number;
    /** Amount tax included for the shipping, with currency */
    shippingDisplayPrice?: string;
    /** Amount tax included of the discount, in cents */
    discountPrice?: number;
    /** Amount tax included of the discount, with currency */
    discountDisplayPrice?: string;
    /** Total vat, in cents */
    totalVat?: number;
    /** Total vat, with currency */
    totalVatDisplayed?: string;
    /** Total price tax included without shipping, in cents (order discount included) */
    totalPriceWithoutShipping?: number;
    /** Total price tax included without shipping, with currency (order discount included) */
    totalDisplayPriceWithoutShipping?: string;
    /** Total price tax included, in cents */
    totalPrice?: number;
    /** Total price tax included, with currency */
    totalDisplayPrice?: string;
    /** Amount sales tax, in cents */
    amountSalesTax?: number;
    /** Amount sales tax, with currency */
    amountSalesTaxDisplay?: string;
    /** Amount duties and other fees, in cents */
    amountDutiesAndOtherFees?: number;
    /** Amount duties and other fees, with currency */
    amountDutiesAndOtherFeesDisplay?: string;
    currencyCode?: string | null;
    products?: OrderProduct[];
    vouchers?: OrderVoucher[];
    userCredits?: OrderVoucher[];
    ecards?: OrderVoucher[];
    /** External id from international service (globalE ; only for Order) */
    externalId?: string | null;
    shipping?: Shipping;
    addresses?: {
        shipping?: Address;
        billing?: Address;
    };
    /** @default [] */
    mergedOrderReferences?: number[];
    orderReferenceBarcodePath?: string | null;
    /** order status */
    status?:
        | 'created'
        | 'pending'
        | 'to_validate'
        | 'validated'
        | 'sent_to_logistic'
        | 'integrated'
        | 'in_preparation'
        | 'packed'
        | 'shipped'
        | 'canceled'
        | 'delivered'
        | 'payment_failed'
        | 'delivered_to_customer'
        | null;
    /** Payment status */
    paymentStatus?: 'waiting' | 'failed' | 'canceled' | 'validated' | null;
    localeCurrencyCode?: string | null;
    localeSubTotalPrice?: number | null;
    localeSubTotalDisplayPrice?: string | null;
    localeTotalPriceWithoutShipping?: number | null;
    localeTotalDisplayPriceWithoutShipping?: string | null;
    localeTotalPrice?: number | null;
    localeTotalDisplayPrice?: string | null;
    localeShippingPrice?: number | null;
    localeShippingDisplayPrice?: string | null;
    localeTotalVat?: number | null;
    localeTotalVatDisplayed?: string | null;
    localeAmountSalesTax?: number | null;
    localeAmountSalesTaxDisplay?: string | null;
    localeAmountDutiesAndOtherFees?: number | null;
    localeAmountDutiesAndOtherFeesDisplay?: string | null;
    localeDiscountPrice?: number | null;
    localeDiscountDisplayPrice?: string | null;
    refund_hold_amount?: number | null;
    /** @default false */
    isPoshmarkResellable?: boolean | null;
    /** @default false */
    hasReturnLabel?: boolean | null;
}

export type Shipping = {
    shippingModeName?: string | null;
    shippingModeType?:
        | 'home_delivery'
        | 'store_delivery'
        | 'pick_up_point'
        | 'last_minute_shopping'
        | 'electronic'
        | 'globale'
        | 'hand_delivery'
        | null;
    logisticCode?: string | null;
    shippingTrackingCode?: string | null;
    shippingTrackingLink?: string | null;
};

export interface WelcomeTrackDeliveryInput {
    DATA?: {
        Delivery_Date?: string;
        Delivery_PendingDate?: string;
        Order_Reference?: string;
    }[];
    DATE?: string;
    WEBHOOK_ID?: number;
    ID?: number;
    TYPE?: string;
    LABEL?: string;
}

export interface ReturnMode {
    /**
     * uid of the linked return mode
     * @format uuid
     */
    uid?: string | null;
    id?: number;
    /** The name of the return mode, translated to the given locale */
    name?: string;
    type?: 'return_label' | 'schneider' | 'free' | 'store_return' | 'dhl_express';
}

export interface EligibleOrderReturnModes {
    returnModes?: ReturnMode[];
    shops?: ShopList;
}

export interface ReturnableData {
    returnableItems?: OrderProduct[];
    nonReturnableItems?: OrderProduct[];
    hasGift?: boolean;
}

export interface OrderRefundOptions {
    /** @default [] */
    availableRefundModes?: string[];
    /** @default [] */
    additionalInformations?: OrderAdditionalInformation[];
}

export interface OrderAdditionalInformation {
    paymentMethodName?: string;
    title?: TranslatableObject;
    subtitle?: TranslatableObject;
}

export interface Context {
    siteCode?: string;
    currencyCode?: string;
    brandCode?: string;
    localeCode?: string | null;
}

export interface Subscription {
    email: string | null;
    /** @default true */
    subscribe?: boolean;
}

export interface Notifications {
    newsletter?: NotificationSubscription[] | null;
    sms?: NotificationSubscription[] | null;
    postal_mail?: NotificationSubscription[] | null;
}

export interface NotificationSubscription {
    marketingBrandCode: string;
    status: boolean | null;
}

export interface PaymentMethod {
    reference?: string;
    type?: string;
    number?: string;
    expiryMonth?: string;
    expiryYear?: string;
    holderName?: string;
}

export interface InfoColisData {
    orderId: string;
    status: string;
    returnTrackingNumber: string;
    trackingNumber: string;
    trackingUrl: string | null;
    /** Date with Ymd format */
    dateEvent: string | null;
}

export interface OrderReturnWrite {
    refundOption: 'card' | 'credits';
    returnMode?: {
        /**
         * uid of the linked return mode
         * @format uuid
         */
        uid: string;
    } | null;
    /** @minItems 1 */
    returnedItems: OrderReturnItemWrite[];
    /**
     * Required with DHL Express pickup at home mode
     * @format date-time
     */
    pickupDateAndTime?: string | null;
    /**
     * Required with DHL Express pickup at home mode, fill it with the day & time, not only time
     * @format date-time
     */
    pickupClosingTime?: string | null;
    /** Required with DHL Express pickup at home mode */
    pickupAddress?: OptionalPhysicalAddress;
}

export interface OrderReturnItemWrite {
    /** @default 0 */
    expectedQuantity: number;
    returnMotive:
        | 'TOO_BIG'
        | 'TOO_SMALL'
        | 'DEFECTIVE'
        | 'NOT_MATCHING'
        | 'STYLE_DOESNT_FIT'
        | 'TOO_SHORT'
        | 'TOO_LONG'
        | null;
    orderLineItem: {
        /**
         * uid of the linked order item
         * @format uuid
         */
        uid: string;
    };
}

export interface OrderReturn {
    id?: number;
    /** @format uuid */
    uid?: string;
    rmaNumber?: number | null;
    status?: OrderReturnStatus;
    /** @default [] */
    brandCodes?: ('sezane' | 'octobre')[];
    refundOption?: 'card' | 'credits';
    /** @default 0 */
    expectedRefundAmount?: number;
    /** @default 0 */
    receivedRefundAmount?: number;
    returnedItems?: OrderReturnItem[];
    /** @default [] */
    refunds?: OrderRefund[];
    /** @default null */
    returnMode?: {
        /**
         * uid of the linked return mode
         * @format uuid
         */
        uid?: string;
        id?: number;
    } | null;
    /** @format date-time */
    transporterHandOverDate?: string | null;
    trackingNumber?: string | null;
    /** @format uuid */
    warehouse_uid?: string | null;
    refund_hold_amount?: number | null;
    refund_mode?: string | null;
}

export interface OrderRefund {
    id?: number;
    /** @min 0 */
    amount?: number;
}

export interface OrderReturnItem {
    expectedQuantity?: number;
    receivedQuantity?: number;
    returnMotive?: string | null;
    orderLineItem?: {
        id?: number;
        /** @format uuid */
        uid?: string;
    } | null;
    ean?: string;
    orderReference?: number | null;
}

export interface WelcomeTrackHandOverInput {
    DATA?: {
        Delivery_FirstScanDate?: string;
        Order_Reference?: string;
    }[];
}

export enum OrderReturnStatus {
    Pending = 'pending',
    ToBeProcessed = 'to_be_processed',
    Processed = 'processed',
    RefundedEarly = 'refunded_early',
    Canceled = 'canceled',
    InTransit = 'in_transit',
    PendingRefund = 'pending_refund',
    RefundsCanceled = 'refunds_canceled',
    RefundsFailed = 'refunds_failed',
}

export interface UserOrderReturn {
    id?: number;
    /** @format uuid */
    uid?: string;
    /**
     * Creation date
     * @format date-time
     */
    createdAt?: string;
    status?: OrderReturnStatus;
    /** The name of the return mode, translated to the given locale */
    returnMode?: string | null;
    /** Type of the return mode */
    returnModeType?: string | null;
    orderReferences?: number[];
    /** the reference of the first order made among the linked orders, in terms of creation date. null if the order is not linked to an order. */
    firstOrderReference?: number | null;
    imageUrl?: string | null;
    /** the total refund amount */
    totalRefundAmount?: string | null;
    /** the total refund amount */
    localeTotalRefundAmount?: string | null;
    /** the total number of items in the return */
    totalReturnItems?: number | null;
    trackingCode?: string | null;
    returnTrackingLink?: string | null;
    /**
     * Required with DHL Express pickup at home mode
     * @format date-time
     */
    pickupDateAndTime?: string | null;
    /**
     * Required with DHL Express pickup at home mode, fill it with the day & time, not only time
     * @format date-time
     */
    pickupClosingTime?: string | null;
    externalId?: string | null;
    /** true if the return is on globalE orders */
    isGlobale?: boolean | null;
    /** @default false */
    hasReturnLabel?: boolean;
    shippingReturnTrackingLink?: string | null;
    /** @format uuid */
    warehouse_uid?: string | null;
    refund_hold_amount?: number | null;
    addresses?: {
        shipping?: UserAddress;
        billing?: UserAddress;
    } | null;
    pickupAddress?: OptionalPhysicalAddress;
    returnedItems?: {
        expectedQuantity?: number;
        receivedQuantity?: number;
        returnMotive?: string | null;
        orderLineItem?: {
            id?: number;
            /** @format uuid */
            uid?: string;
            imageUrl?: string;
            productUrl?: string;
            label?: string;
            /** Variant code of the product (none for ecard) */
            variantCode?: string | null;
            sizeLabel?: string;
            colorLabel?: string | null;
            unitPrice?: number;
            localeUnitPrice?: number | null;
            totalPrice?: number;
            localeTotalPrice?: number | null;
            /** Price to display, with currency */
            displayPrice?: string;
            localeDisplayPrice?: string | null;
            /** Total price to display, with currency */
            totalDisplayPrice?: string;
            localeTotalDisplayPrice?: string | null;
        };
    }[];
    refund_mode?: string | null;
    /**
     * Messages to display
     * @default []
     */
    additionalInformation?: AdditionalInformation[];
}

export interface AdditionalInformation {
    name?: string;
    title?: TranslatableObject;
    subtitle?: TranslatableObject;
}

export type ShopListRetail = ShopRetail[];

export type ShopRetail = {
    id?: number;
    uid?: string | null;
    /** @minLength 1 */
    brandCode: string;
    /** @minLength 1 */
    description: string;
    /** @default false */
    enabled?: boolean;
    address: PhysicalAddress;
    phone?: string | null;
    vatNumber?: string | null;
    /** @minLength 1 */
    timezone: string;
    openHours: OpenHours | null;
    defaultSiteCode: string;
    defaultCurrencyCode: string;
    defaultLocaleCode?: string;
    /** @default false */
    hasAppleTapToPayEnabled?: boolean;
    [key: string]: any;
};

export interface PackageCreatedPostBody {
    details?: {
        account?: {
            /** @example 451871 */
            id?: number;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1676298576" */
            reference?: string | null;
            /** @example "https://stuart.sandbox.followmy.delivery/100626384/d49130ecd327207eedfcc134b04f8213" */
            tracking_url?: string;
        };
    };
    /** @example "93ce4c52-5b27-4cec-9baf-68cfcdd4da46" */
    event_id?: string;
    /** @example "2023-02-13T15:29:36.000+01:00" */
    occurred_at?: string;
    /** @example "package_created" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2359 */
    webhook_id?: number;
}

export interface CourierAssignedPostBody {
    details?: {
        account?: {
            /** @example 451871 */
            id?: number;
        };
        courier?: {
            /** @example "Adriel Pfeffer" */
            name?: string;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1682689274" */
            reference?: string | null;
            /** @example "https://stuart.sandbox.followmy.delivery/100670892/1f25824f8d9c8999406c93406670b264" */
            tracking_url?: string;
        };
    };
    /** @example "49b42175-8c53-4003-aac0-8558876b422b" */
    event_id?: string;
    /** @example "2023-04-28T15:41:29.000+02:00" */
    occurred_at?: string;
    /** @example "courier_assigned" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2510 */
    webhook_id?: number;
}

export interface CourierArrivingPostBody {
    details?: {
        account?: {
            /** @example 1 */
            id?: number;
        };
        courier?: {
            /** @example 12001 */
            id?: number;
            /** @example "Leta Boehm" */
            name?: string;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1676298576" */
            reference?: string | null;
        };
        proximity_radius?: {
            /** @example 36 */
            magnitude?: number;
            /** @example "meters" */
            unit?: string;
        };
        /** @example "dropoff" */
        task?: string;
    };
    /** @example "d60e5f40-5c6e-4f8e-b836-c8de2171f3df" */
    event_id?: string;
    /** @example "2023-01-10T17:18:42.326+01:00" */
    occurred_at?: string;
    /** @example "courier_arriving" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2359 */
    webhook_id?: number;
}

export interface CourierWaitingPostBody {
    details?: {
        account?: {
            /** @example 1 */
            id?: number;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1676298576" */
            reference?: string | null;
            /** @example "https://stuart.sandbox.followmy.delivery/100626384/d49130ecd327207eedfcc134b04f8213" */
            tracking_url?: string;
        };
        /** @example "dropoff" */
        task?: string;
    };
    /** @example "d26bae6c-e309-495f-8b46-0eb91e7c8824" */
    event_id?: string;
    /** @example "2023-02-13T15:52:58.976+01:00" */
    occurred_at?: string;
    /** @example "courier_waiting" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2359 */
    webhook_id?: number;
}

export interface PackageDeliveringPostBody {
    details?: {
        account?: {
            /** @example 1 */
            id?: number;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1676298576" */
            reference?: string | null;
            /** @example "https://stuart.sandbox.followmy.delivery/100626384/d49130ecd327207eedfcc134b04f8213" */
            tracking_url?: string;
        };
    };
    /** @example "2551af2a-54bf-4b82-af91-35cee45f4af5" */
    event_id?: string;
    /** @example "2023-02-13T15:30:44.000+01:00" */
    occurred_at?: string;
    /** @example "package_delivering" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2359 */
    webhook_id?: number;
}

export interface PackageDeliveredPostBody {
    details?: {
        account?: {
            /** @example 451871 */
            id?: number;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1683036378" */
            reference?: string | null;
            /** @example "https://stuart.sandbox.followmy.delivery/100671414/ae7e5fa8cc57d5f535356c2bce481abf" */
            tracking_url?: string;
        };
    };
    /** @example "ab1f8bcc-7eb8-48e7-9ba6-5f0c720dd231" */
    event_id?: string;
    /** @example "2023-05-02T16:08:57.000+02:00" */
    occurred_at?: string;
    /** @example "package_delivered" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2515 */
    webhook_id?: number;
}

export interface PackageCanceledPostBody {
    details?: {
        account?: {
            /** @example 1 */
            id?: number;
        };
        cancelation?: {
            /** @example "client" */
            actor?: string;
            /** @example null */
            comment?: any;
            /** @example "address_error" */
            key?: string;
            /** @example "Address error" */
            reason?: string;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1676300282" */
            reference?: string | null;
        };
    };
    /** @example "e8dc1cb4-2e75-4b11-9255-770cebcecd82" */
    event_id?: string;
    /** @example "2023-02-13T15:58:42.000+01:00" */
    occurred_at?: string;
    /** @example "package_canceled" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2359 */
    webhook_id?: number;
}

export interface PackageReturningPostBody {
    details?: {
        account?: {
            /** @example 1 */
            id?: number;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1676300384" */
            reference?: string | null;
        };
        return?: {
            /** @example "end-customer_unreachable" */
            key?: string;
            /** @example "End-customer unreachable" */
            reason?: string;
        };
    };
    /** @example "515b417c-49d1-460b-867c-00c6bd1ce6fe" */
    event_id?: string;
    /** @example "2023-02-13T16:00:36.000+01:00" */
    occurred_at?: string;
    /** @example "package_returning" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2359 */
    webhook_id?: number;
}

export interface PackageReturnedPostBody {
    details?: {
        account?: {
            /** @example 1 */
            id?: number;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1676300384" */
            reference?: string | null;
        };
        return?: {
            /** @example "operations" */
            actor?: string;
            /** @example "end-customer_unreachable" */
            key?: string;
            /** @example "End-customer unreachable" */
            reason?: string;
        };
    };
    /** @example "91ad6f82-d62a-4e4c-87d7-9988697e044e" */
    event_id?: string;
    /** @example "2023-02-13T16:01:25.000+01:00" */
    occurred_at?: string;
    /** @example "package_returned" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2359 */
    webhook_id?: number;
}

export interface CourierReassigningPostBody {
    details?: {
        account?: {
            /** @example 451871 */
            id?: number;
        };
        coordinates?: {
            /** @example 51.409676 */
            lat?: number;
            /** @example -0.230138 */
            long?: number;
        };
        package?: {
            /** @example "10000000001" */
            id?: string;
            /** @example "1682689274" */
            reference?: string | null;
        };
        /** @example "dropoff" */
        task?: string;
    };
    /** @example "021736cf-4396-4fb0-a74c-09f9ca04a4e6" */
    event_id?: string;
    /** @example "2023-12-11T10:19:25.394+01:00" */
    occurred_at?: string;
    /** @example "courier_moving" */
    topic?: string;
    /** @example "v3" */
    version?: string;
    /** @example 2510 */
    webhook_id?: number;
}

export interface References {
    order_return_motives?: {
        name?: string;
        translation?: string;
    }[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key]);
        return keys
            .map(key =>
                Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    };

    protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
        }).then(async response => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then(data => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch(e => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title Sezane OMS public API
 * @version 0.0.1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    omsPublic = {
        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetPickUps
         * @summary Get a set of pick ups by postcode/city or latitude/longitude.
         * @request GET:/oms-public/v1/pickup/{country}
         */
        getPickUps: (
            country: string,
            query?: {
                /** The postcode if we want to search by postcode/city. */
                postcode?: string;
                /** The city if we want to search by postcode/city. */
                city?: string;
                /** The latitude in case we want to search by geo coordinates in XSD format (ex 41.403). */
                lat?: string;
                /** The longitude in case we want to search by geo coordinates in XSD format (ex 2.174). */
                long?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PickUp[], Rfc7807>({
                path: `/oms-public/v1/pickup/${country}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags cart
         * @name GetCart
         * @summary Get a cart
         * @request GET:/oms-public/v1/cart/{uid}
         */
        getCart: (
            uid: string,
            query?: {
                /**
                 * Check the stock for packaging options
                 * @default true
                 */
                checkPackagingStock?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<Cart, Rfc7807 | void>({
                path: `/oms-public/v1/cart/${uid}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags cart
         * @name AddProduct
         * @summary Add an entry in the cart
         * @request POST:/oms-public/v1/cart/{uid}/product
         */
        addProduct: (
            uid: string,
            query: {
                /** brand code */
                brandCode: string;
                /** site code */
                siteCode: string;
                /** currency code */
                currencyCode: string;
            },
            data: AddProductRequestBody,
            params: RequestParams = {}
        ) =>
            this.request<Cart, Rfc7807 | void>({
                path: `/oms-public/v1/cart/${uid}/product`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get references list
         *
         * @tags oms-public, references
         * @name GetReferencesList
         * @summary Get references list
         * @request GET:/oms-public/v1/references
         */
        getReferencesList: (
            query?: {
                siteCode?: string;
                currencyCode?: string;
                brandCode?: string;
                localeCode?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<References, any>({
                path: `/oms-public/v1/references`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    };
    omsAuthenticated = {
        /**
         * @description create cart and assign user from the JWT token and add an entry in the cart
         *
         * @tags cart
         * @name CreateCartAndAddProduct
         * @summary Create, assign user and add an entry in the cart
         * @request POST:/oms-authenticated/v1/cart/product
         */
        createCartAndAddProduct: (
            query: {
                /** brand code */
                brandCode: string;
                /** site code */
                siteCode: string;
                /** country code */
                countryCode?: string;
                /** region code corresponding to state code for US */
                regionCode?: string;
                currencyCode?: string;
                /**
                 * True if it s a globale cart
                 * @default false
                 */
                globale?: boolean;
            },
            data: AddProductRequestBody,
            params: RequestParams = {}
        ) =>
            this.request<Cart, Rfc7807 | void>({
                path: `/oms-authenticated/v1/cart/product`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user
         * @name GetUser
         * @summary Get a user
         * @request GET:/oms-authenticated/v1/user/{keycloakId}
         */
        getUser: (
            keycloakId: string,
            query: {
                brandCode: string;
                siteCode: string;
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<User, Rfc7807>({
                path: `/oms-authenticated/v1/user/${keycloakId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user
         * @name UpdateUser
         * @summary Update a user
         * @request PUT:/oms-authenticated/v1/user
         */
        updateUser: (
            query: {
                brandCode: string;
            },
            data: User,
            params: RequestParams = {}
        ) =>
            this.request<User, Rfc7807>({
                path: `/oms-authenticated/v1/user`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Delete the authenticated user. This endpoint is only available for authenticated users.
         *
         * @tags user
         * @name DeleteUserAuthenticated
         * @summary Delete the authenticated user
         * @request DELETE:/oms-authenticated/v1/user
         */
        deleteUserAuthenticated: (
            query: {
                siteCode: string;
                brandCode: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, Rfc7807>({
                path: `/oms-authenticated/v1/user`,
                method: 'DELETE',
                query: query,
                ...params,
            }),

        /**
         * @description Get paginated user alerts.
         *
         * @tags oms, user-alert
         * @name GetAlertsAuthenticated
         * @summary Get paginated user alerts
         * @request GET:/oms-authenticated/v1/user/alerts
         */
        getAlertsAuthenticated: (
            query: {
                brandCode: string;
                /** @default 1 */
                page?: number;
                /**
                 * Indicate number of items to retrieve
                 * @default 12
                 */
                itemsPerPage?: number;
                siteCode: string;
                currencyCode: string;
                countryCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<WishlistAndAlertItem[], void>({
                path: `/oms-authenticated/v1/user/alerts`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Add user alert for ean.
         *
         * @tags oms, user-alert
         * @name AddAlertAuthenticated
         * @summary Add user alert for ean
         * @request POST:/oms-authenticated/v1/user/alerts/{ean}
         */
        addAlertAuthenticated: (
            ean: string,
            query: {
                brandCode: string;
                siteCode: string;
                locale: string;
                currencyCode: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<WishlistAndAlertItem, void>({
                path: `/oms-authenticated/v1/user/alerts/${ean}`,
                method: 'POST',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Delete user alert for ean.
         *
         * @tags oms, user-alert
         * @name DeleteAlertAuthenticated
         * @summary Delete user alert for ean
         * @request DELETE:/oms-authenticated/v1/user/alerts/{ean}
         */
        deleteAlertAuthenticated: (ean: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/oms-authenticated/v1/user/alerts/${ean}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * @description Replace given ean with a new one in existing user alert.
         *
         * @tags oms, user-alert
         * @name ReplaceAlertAuthenticated
         * @summary Replace given ean with a new one in existing user alert
         * @request PATCH:/oms-authenticated/v1/user/alerts/{ean}
         */
        replaceAlertAuthenticated: (
            ean: string,
            query: {
                brandCode: string;
                siteCode: string;
                locale: string;
                currencyCode: string;
            },
            data: {
                newEan: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<WishlistAndAlertItem, void>({
                path: `/oms-authenticated/v1/user/alerts/${ean}`,
                method: 'PATCH',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, user-address
         * @name GetAddresses
         * @summary Get user paginated addresses
         * @request GET:/oms-authenticated/v1/user/{keycloakUserId}/address
         */
        getAddresses: (
            keycloakUserId: string,
            query?: {
                /** Country code to filter */
                countryCode?: string;
                /** @default 1 */
                page?: number;
                /** @default 12 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<UserAddress[], Rfc7807>({
                path: `/oms-authenticated/v1/user/${keycloakUserId}/address`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, user-address
         * @name AddAddress
         * @summary Create a new user address
         * @request POST:/oms-authenticated/v1/user/{keycloakUserId}/address
         */
        addAddress: (keycloakUserId: string, data: UserAddress, params: RequestParams = {}) =>
            this.request<UserAddress, Rfc7807>({
                path: `/oms-authenticated/v1/user/${keycloakUserId}/address`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, user-address
         * @name DeleteAddress
         * @summary Delete user address
         * @request DELETE:/oms-authenticated/v1/user/{keycloakUserId}/address/{uid}
         */
        deleteAddress: (keycloakUserId: string, uid: string, params: RequestParams = {}) =>
            this.request<void, Rfc7807>({
                path: `/oms-authenticated/v1/user/${keycloakUserId}/address/${uid}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, user-address
         * @name ReplaceAddress
         * @summary Update user address
         * @request PUT:/oms-authenticated/v1/user/{keycloakUserId}/address/{uid}
         */
        replaceAddress: (keycloakUserId: string, uid: string, data: UserAddress, params: RequestParams = {}) =>
            this.request<UserAddress, Rfc7807>({
                path: `/oms-authenticated/v1/user/${keycloakUserId}/address/${uid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description This endpoint is used to retrieve an ecard for a user. The user must be authenticated.
         *
         * @tags oms, ecard, user
         * @name GetEcardAuthenticated
         * @summary Get an ecard
         * @request POST:/oms-authenticated/v1/user/ecard
         */
        getEcardAuthenticated: (
            query: {
                siteCode: string;
                brandCode: string;
            },
            data: RetrieveUserEcardBodySchema,
            params: RequestParams = {}
        ) =>
            this.request<RetrieveUserEcardResponseOkSchema, Rfc7807 | void>({
                path: `/oms-authenticated/v1/user/ecard`,
                method: 'POST',
                query: query,
                body: data,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, credit, user
         * @name GetCredits
         * @summary Get credit list
         * @request GET:/oms-authenticated/v1/user/{keycloakUserId}/credits
         */
        getCredits: (
            keycloakUserId: string,
            query: {
                brandCode: string;
                siteCode: string;
                currencyCode: string;
                countryCode: string;
                /** @default 1 */
                page?: number;
                /**
                 * Indicate number of items to retrieve
                 * @default 4
                 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<UserCredit[], Rfc7807 | void>({
                path: `/oms-authenticated/v1/user/${keycloakUserId}/credits`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get paginated wishlist items for logged user. This endpoint is only available for logged users.
         *
         * @tags oms, user-wishlist
         * @name GetWishlistItemsAuthenticated
         * @summary Get paginated wishlist items for logged user
         * @request GET:/oms-authenticated/v1/user/wishlist
         */
        getWishlistItemsAuthenticated: (
            query: {
                brandCode: string;
                siteCode: string;
                /** @default 1 */
                page?: number;
                /**
                 * Indicate number of items to retrieve
                 * @default 12
                 */
                itemsPerPage?: number;
                /** Return only the last n items available */
                lastOnesAvailable?: boolean;
                currencyCode: string;
                countryCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<WishlistAndAlertItem[], Rfc7807>({
                path: `/oms-authenticated/v1/user/wishlist`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Delete wishlist item for logged user. This endpoint is only available for logged users.
         *
         * @tags oms, user-wishlist
         * @name DeleteWishlistItemAuthenticated
         * @summary Delete wishlist item for logged user
         * @request DELETE:/oms-authenticated/v1/user/wishlist/{ean}
         */
        deleteWishlistItemAuthenticated: (ean: string, params: RequestParams = {}) =>
            this.request<void, Rfc7807>({
                path: `/oms-authenticated/v1/user/wishlist/${ean}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * @description Replace given ean with a new one in existing logged user wishlist. This endpoint is only available for logged users.
         *
         * @tags oms, user-wishlist
         * @name ReplaceWishlistItemAuthenticated
         * @summary Replace given ean with a new one in existing logged user wishlist
         * @request PATCH:/oms-authenticated/v1/user/wishlist/{ean}
         */
        replaceWishlistItemAuthenticated: (
            ean: string,
            query: {
                brandCode: string;
                siteCode: string;
                currencyCode: string;
            },
            data: NewEanObject,
            params: RequestParams = {}
        ) =>
            this.request<WishlistAndAlertItem, Rfc7807>({
                path: `/oms-authenticated/v1/user/wishlist/${ean}`,
                method: 'PATCH',
                query: query,
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-return
         * @name GetReturnableItems
         * @summary Get items that can be returned in an order (including items linked to orders merged to the given order)
         * @request GET:/oms-authenticated/v1/order/{orderReference}/returnable-items
         */
        getReturnableItems: (orderReference: string, params: RequestParams = {}) =>
            this.request<ReturnableData, void | Rfc7807>({
                path: `/oms-authenticated/v1/order/${orderReference}/returnable-items`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-return, order-refund
         * @name GetRefundOptions
         * @summary Get the available refund options for a given order (as well as the payment methods used when ordering)
         * @request GET:/oms-authenticated/v1/order/{orderReference}/refund-options
         */
        getRefundOptions: (orderReference: string, params: RequestParams = {}) =>
            this.request<OrderRefundOptions, void | Rfc7807>({
                path: `/oms-authenticated/v1/order/${orderReference}/refund-options`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-return
         * @name GetOrderReturnLabelFromOrder
         * @summary Get the pdf file of the order return label
         * @request GET:/oms-authenticated/v1/orders/{orderReference}/return-label
         */
        getOrderReturnLabelFromOrder: (orderReference: string, params: RequestParams = {}) =>
            this.request<File, void | Rfc7807>({
                path: `/oms-authenticated/v1/orders/${orderReference}/return-label`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-return
         * @name GetReturnableOrders
         * @summary Get orders that can be returned
         * @request GET:/oms-authenticated/v1/order/returnable
         */
        getReturnableOrders: (params: RequestParams = {}) =>
            this.request<OrderRead[], Rfc7807>({
                path: `/oms-authenticated/v1/order/returnable`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name CancelOrder
         * @summary cancel order (with no siblings)
         * @request DELETE:/oms-authenticated/v1/order/{orderReference}
         */
        cancelOrder: (orderReference: string, params: RequestParams = {}) =>
            this.request<OrderRead[], Rfc7807>({
                path: `/oms-authenticated/v1/order/${orderReference}`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name GetEligibleOrderReturnModes
         * @summary Get return modes for the given order, translated to the given locale
         * @request GET:/oms-authenticated/v1/order/{orderReference}/return-mode
         */
        getEligibleOrderReturnModes: (
            orderReference: string,
            query: {
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<EligibleOrderReturnModes, Rfc7807>({
                path: `/oms-authenticated/v1/order/${orderReference}/return-mode`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name MarkLineItemAsResellableOnPoshmark
         * @summary create a poshmark resell for a line item
         * @request POST:/oms-authenticated/v1/order/{orderReference}/lineitem/{lineItemUuid}/poshmark-resell
         */
        markLineItemAsResellableOnPoshmark: (
            orderReference: string,
            lineItemUuid: string,
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    data?: {
                        url?: string;
                    };
                },
                Rfc7807 | void
            >({
                path: `/oms-authenticated/v1/order/${orderReference}/lineitem/${lineItemUuid}/poshmark-resell`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * @description Get paginated orders for logged user. This endpoint is only available for logged users.
         *
         * @tags oms, user-order
         * @name ListUserOrdersAuthenticated
         * @summary Get paginated orders for logged user
         * @request GET:/oms-authenticated/v1/user/orders
         */
        listUserOrdersAuthenticated: (
            query: {
                brandCode: string;
                /** @default 1 */
                page?: number;
                /** @default 4 */
                itemsPerPage?: number;
                orderIds?: any[];
                /** ISO 8601 date for the start of the period */
                dateFrom?: string;
                /** ISO 8601 date for the end of the period */
                dateTo?: string;
                countryCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<OrderRead[], Rfc7807 | void>({
                path: `/oms-authenticated/v1/user/orders`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get summary of orders for logged user. This endpoint is only available for logged users.
         *
         * @tags oms, user-order
         * @name SummaryUserOrdersAuthenticated
         * @summary Get summary of orders for logged user
         * @request GET:/oms-authenticated/v1/user/orders-summary
         */
        summaryUserOrdersAuthenticated: (
            query: {
                /** Brand code (example: sezane OR octobre) */
                brandCode: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    summary?: {
                        yearMonth?: string;
                        nbOrders?: number;
                    }[];
                },
                void | Rfc7807
            >({
                path: `/oms-authenticated/v1/user/orders-summary`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get an order for logged user. This endpoint is only available for logged users.
         *
         * @tags oms, user-order
         * @name GetUserOrderAuthenticated
         * @summary Get an order for logged user
         * @request GET:/oms-authenticated/v1/user/orders/{id}
         */
        getUserOrderAuthenticated: (
            id: string,
            query?: {
                countryCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<OrderRead, Rfc7807 | void>({
                path: `/oms-authenticated/v1/user/orders/${id}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description list payment methods saved by Adyen for logged user. This endpoint is only available for logged users.
         *
         * @tags oms, user-payment-methods
         * @name GetUserPaymentMethodsAuthenticated
         * @summary list payment methods saved by Adyen for logged user
         * @request GET:/oms-authenticated/v1/user/payment/methods
         */
        getUserPaymentMethodsAuthenticated: (
            query: {
                brandCode: string;
                siteCode: string;
                currencyCode: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaymentMethod[], Rfc7807>({
                path: `/oms-authenticated/v1/user/payment/methods`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description delete payment method saved by Adyen for logged user. This endpoint is only available for logged users.
         *
         * @tags oms, user-payment-methods
         * @name DeleteUserPaymentMethodsAuthenticated
         * @summary delete payment method saved by Adyen for logged user
         * @request DELETE:/oms-authenticated/v1/user/payment/methods/{reference}
         */
        deleteUserPaymentMethodsAuthenticated: (
            reference: string,
            query: {
                brandCode: string;
                siteCode: string;
                currencyCode: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, Rfc7807>({
                path: `/oms-authenticated/v1/user/payment/methods/${reference}`,
                method: 'DELETE',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-return, oms
         * @name CreateOrderReturnAuthenticated
         * @summary Create a new order return
         * @request POST:/oms-authenticated/v1/order-return
         */
        createOrderReturnAuthenticated: (data: OrderReturnWrite, params: RequestParams = {}) =>
            this.request<UserOrderReturn, Rfc7807>({
                path: `/oms-authenticated/v1/order-return`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-return, oms
         * @name GetOrderReturnDhlTimezoneRequiredCountriesTimezones
         * @summary Get DHL returns countries with TZ required and related timezones
         * @request GET:/oms-authenticated/v1/order-return/dhl-timezones
         */
        getOrderReturnDhlTimezoneRequiredCountriesTimezones: (params: RequestParams = {}) =>
            this.request<
                {
                    timezones?: Record<string, Record<string, string>>;
                },
                Rfc7807 | void
            >({
                path: `/oms-authenticated/v1/order-return/dhl-timezones`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-return, oms
         * @name GetOrderReturnAuthenticated
         * @summary Get order return
         * @request GET:/oms-authenticated/v1/order-return/{uid}
         */
        getOrderReturnAuthenticated: (
            uid: string,
            query?: {
                countryCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<UserOrderReturn, Rfc7807 | void>({
                path: `/oms-authenticated/v1/order-return/${uid}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-return, oms, dhl
         * @name GetOrderReturnDhlSlotsAuthenticated
         * @summary Get DHL Express
         * @request POST:/oms-authenticated/v1/order-returns/dhl-slot/{id}
         */
        getOrderReturnDhlSlotsAuthenticated: (id: number, data: OptionalPhysicalAddress, params: RequestParams = {}) =>
            this.request<
                {
                    slots?: {
                        id?: string;
                        /** @format date-time */
                        startTime?: string;
                        /** @format date-time */
                        closeTime?: string;
                    }[];
                },
                Rfc7807 | void
            >({
                path: `/oms-authenticated/v1/order-returns/dhl-slot/${id}`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-return, oms, dhl
         * @name CheckAddressForDhlPickupAuthenticated
         * @summary Check address onto DHL Express API
         * @request POST:/oms-authenticated/v1/order-returns/dhl-address
         */
        checkAddressForDhlPickupAuthenticated: (data: OptionalPhysicalAddress, params: RequestParams = {}) =>
            this.request<
                {
                    result?: 'ok';
                },
                {
                    result?: 'ko';
                }
            >({
                path: `/oms-authenticated/v1/order-returns/dhl-address`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-return, oms
         * @name CancelOrderReturnAuthenticated
         * @summary Cancel order return
         * @request PUT:/oms-authenticated/v1/order-return/{uid}/cancel
         */
        cancelOrderReturnAuthenticated: (uid: string, params: RequestParams = {}) =>
            this.request<UserOrderReturn, Rfc7807 | void>({
                path: `/oms-authenticated/v1/order-return/${uid}/cancel`,
                method: 'PUT',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-return, oms
         * @name GetUserOrderReturnsAuthenticated
         * @summary Get the user's order returns
         * @request GET:/oms-authenticated/v1/order-returns
         */
        getUserOrderReturnsAuthenticated: (
            query: {
                brandCode: string;
                /** @default 1 */
                page?: number;
                /** @default 4 */
                itemsPerPage?: number;
                countryCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<UserOrderReturn[], Rfc7807>({
                path: `/oms-authenticated/v1/order-returns`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags invoice, order
         * @name GetInvoice
         * @summary Get invoice PDF for a given order
         * @request GET:/oms-authenticated/v1/invoice/{orderReference}
         */
        getInvoice: (orderReference: string, params: RequestParams = {}) =>
            this.request<File, Rfc7807>({
                path: `/oms-authenticated/v1/invoice/${orderReference}`,
                method: 'GET',
                ...params,
            }),
    };
}
